@use "breakpoints.scss";


.footer {
  margin-top: 3em;
  padding: 10px 0;
  background: var(--footer);
  color: #8ab1ca;
  font-size: 0.9em;



  p {
    margin-bottom: 1em;

    a {
      color: #8ab1ca;
      text-decoration: underline;
    }
  }

  .footerLogo {
    width: 200px;
    height: auto;
    margin-bottom: 10px;
  }

  .footerMenu {


    li {
      margin-bottom: 0.15em;

      a {
        color: #8ab1ca;
      }
    }
  }
}

/* Applies screens larger than (+576px)  */
@media screen and (breakpoints.$smUp) {
  .footer {
    padding: 30px 0;

    .footerLogo {
      width: 300px;
      height: auto;
    }
  }
}



/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
  .footer {
    .footerMenu {
      line-height: initial;
      font-size: 1.2em;
    }
  }

  .sidebarLayoutArea[data-align="main"] {
    float: left;
    margin-right: 2rem;
    width: calc(100% - 240px - 2rem);
  }

  .sidebarLayoutArea[data-align="side"] {
    width: 240px;
    display: inline-block;
  }
}